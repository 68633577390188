import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Intro from "./sections/Intro";
import Join from "./sections/Join";
import Company from "./sections/Company";
import Request from "./sections/Request";
import Event from "./sections/Event";
import Partner from "./sections/Partner";
import Head from "../Base/Head";

export default function SisDelegate() {
  const head = {
    title: "SIS Learning Journey - Delegate Form",
    description:
      "We are honored to have you join us on the Social Innovation Summit Learning Journey in Houston on February 25-26th.",
    url: "https://sislearningjourney.com",
    image: "https://i.imgur.com/otcnge7.png",
  };

  return (
    <>
      <Head head={head} />
      <div className="industrial-tech sis-delegate">
        {/* <Intro /> */}
        {/* <Join /> */}
        {/* <Event /> */}
        <Request />
        {/* <Company /> */}
        {/* <Partner /> */}
      </div>
    </>
  );
}
