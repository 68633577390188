import React, { useState, useEffect } from "react";
import ApiService from "../../services/ApiService";
import { toast } from "react-hot-toast";
import DatePicker from "react-datepicker";
import { AnimatePresence } from "framer-motion";
import SuccessModal from "./SuccessModal";
import {
  dateToYYYYMMDD,
  getDisplayDateFromStartEndDateString,
} from "../../utils";

const tag = "sisdelegate";

export default function Request() {
  const [isShown, setIsShown] = useState(false);
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    company: "",
    jobTitle: "",
    email: "",
    // sis delegate
    phone: "",
    arrivalAirline: "",
    arrivalDate: new Date(),
    arrivalTime: "",
    departureAirline: "",
    departureDate: new Date(),
    departureTime: "",
    dietaryRequirements: "",
    bio: "",
    headshotImg: null,
    digitalEquity: "",
    nominations: "",
  });
  const onChangeInput = (input) => (e) => {
    setForm((form) => ({ ...form, [input]: e.target.value }));
  };
  const onChangeDateInput = (input, date) => {
    const newForm = { ...form };
    newForm[input] = date;
    setForm(newForm);
  };
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  // optionsName - e.g. expertiseOptions
  const optionOnChange = (e, optionsName) => {
    if (e.target.checked) {
      let arr = [...form[optionsName]];
      arr.push(e.target.id);
      const obj = {};
      obj[optionsName] = arr;
      updateForm(obj);
    } else {
      const obj = {};
      obj[optionsName] = form[optionsName].filter(
        (item) => item !== e.target.id
      );
      updateForm(obj);
    }
  };

  const onImageUpload = (event) => {
    const selectedImage = event.target.files[0]; // Get the selected image file

    if (selectedImage) {
      // You can perform additional checks here, like checking file size or type

      // Update the state with the selected image
      setForm({
        ...form,
        headshotImg: selectedImage,
      });
    }
  };

  const CheckboxOptionsSection = ({
    sectionClassname = "",
    anchorId,
    title,
    description,
    optionsName,
    options,
  }) => {
    return (
      <section className={`request ${sectionClassname}`} id={anchorId}>
        <div className="auto__container">
          <div className="request__inner">
            {/* <div className="request__inner-circle">
            <img
              src={process.env.PUBLIC_URL + "/images/circle.png"}
              alt="circle"
            />
          </div> */}
            <div className="requestForm">
              <h3 className="request-heading">{title}</h3>
              <h5 className="request-subheading">{description}</h5>
              <div className="request-container">
                <label className="input__outer">
                  {options.map((option, index) => (
                    <OptionCheckbox
                      key={`option_${index}`}
                      option={option}
                      optionsName={optionsName}
                    />
                  ))}
                </label>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const submitForm = async () => {
    if (!formValid) {
      console.log(`submitForm() - form is invalid`, form);
      return;
    }
    const formCopy = JSON.parse(JSON.stringify(form));
    // set dates
    if (formCopy.arrivalDate) {
      formCopy.arrivalDate = dateToYYYYMMDD(formCopy.arrivalDate);
    }
    if (formCopy.departureDate) {
      formCopy.departureDate = dateToYYYYMMDD(formCopy.departureDate);
    }
    formCopy.headshotImg = form.headshotImg;
    console.log(`submitForm()`, formCopy);

    const formData = new FormData();
    for (const field in formCopy) {
      if (formCopy.hasOwnProperty(field)) {
        formData.append(field, formCopy[field]);
      }
    }
    // formData.append('firstName', firstName);
    // formData.append('lastName', lastName);
    // formData.append('headshotImg', selectedFile);

    try {
      const api = new ApiService();
      const response = await api.post(
        `landmark/delegates/addDelegate`,
        formData,
        { "Content-Type": "multipart/form-data" }
      );
      console.log(`submitForm() - response`, response);
      // toast.success("Your response has been logged. Thank you!");
      setIsShown(true);
      // setCompanies(response);
    } catch (error) {
      console.error("Error submitting form", error);
      toast.error("An error occurred when submitting your response.");
    }
  };

  let formValid = false;
  let formErrorMsg = ``;
  // validate form
  const validateForm = () => {
    const {
      firstName,
      lastName,
      company,
      jobTitle,
      email,
      // sis delegate
      phone,
      arrivalAirline,
      arrivalDate,
      arrivalTime,
      departureAirline,
      departureDate,
      departureTime,
      dietaryRequirements,
      bio,
      headshotImg,
      digitalEquity,
      nominations,
    } = form;
    if (!firstName.length) {
      formErrorMsg = "Please enter your first name";
      return false;
    }
    if (!lastName.length) {
      formErrorMsg = "Please enter your last name";
      return false;
    }
    if (!company.length) {
      formErrorMsg = "Please enter your company";
      return false;
    }
    if (!jobTitle.length) {
      formErrorMsg = "Please enter your job title";
      return false;
    }
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!email.length || !emailRegex.test(email)) {
      formErrorMsg = "Please enter a valid email address";
      return false;
    }
    if (!phone.length) {
      formErrorMsg = "Please enter your cell phone number";
      return false;
    }
    return true;
  };
  formValid = validateForm();
  const submitDisabledClass = formValid ? "" : "disabled";

  const OptionCheckbox = ({ option, optionsName }) => {
    const id = `${option}`;
    return (
      <label htmlFor={id} className="check">
        <div className="check__box">
          <input
            type="checkbox"
            id={id}
            checked={form[optionsName].some((p) => p === option)}
            onChange={(event) => optionOnChange(event, optionsName)}
          />
          <span>
            <img
              src={process.env.PUBLIC_URL + "/images/icons/tick.svg"}
              alt="icon"
            />
          </span>
        </div>
        <div className="check-label">{option}</div>
      </label>
    );
  };

  const SectionOverview = ({
    title,
    description,
    descriptionClass = "",
    imagePath,
    buttonText,
    buttonAnchor,
  }) => {
    return (
      <div className="col-12 col-lg-4">
        <div className="topic-container">
          <div className="title">{title}</div>
          <div className={`desc ${descriptionClass}`}>{description}</div>
          <img
            className="img-fluid"
            src={process.env.PUBLIC_URL + imagePath}
            alt={title}
          />
          <a href={`#${buttonAnchor}`}>
            <div className="action-btn">
              <div>{buttonText}</div>
            </div>
          </a>
        </div>
      </div>
    );
  };

  return (
    <>
      <section className="overview-section">
        <div className="banner-img">
          <img
            className="img-fluid"
            src={
              process.env.PUBLIC_URL +
              "/images/sisdelegate/sis-2025-banner2.png"
            }
            alt={"Social Innovation Summit 2023"}
          />
        </div>
        <div className="section-header-text">
          <h4 className="title">
            We are honored to have you join us on the Social Innovation Summit
            Learning Journey in Houston on February 25-26th!
          </h4>
          <br />
          <h4 className="title">
            To make this special trip as organized and convenient as possible,
            please complete this form by Monday, January 20th.
          </h4>
        </div>
      </section>
      <section className="request last">
        <div className="auto__container">
          <div className="request__inner">
            {/* <div className="request__inner-circle">
            <img
              src={process.env.PUBLIC_URL + "/images/circle.png"}
              alt="circle"
            />
          </div> */}
            <div className="requestForm">
              <h3 className="request-heading" style={{ marginBottom: "16px" }}>
                Personal Information
              </h3>
              {/* <h5 className="request-subheading">{description}</h5> */}
              <div className="requestForm__inputs">
                <label className="input__outer sm">
                  <h5>First Name</h5>
                  <div className="input">
                    <input
                      type="name"
                      value={form.firstName}
                      onChange={onChangeInput("firstName")}
                    />
                  </div>
                </label>
                <label className="input__outer sm">
                  <h5>Last Name</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.lastName}
                      onChange={onChangeInput("lastName")}
                    />
                  </div>
                </label>
                <label className="input__outer">
                  <h5>Company</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.company}
                      onChange={onChangeInput("company")}
                    />
                  </div>
                </label>
                <label className="input__outer">
                  <h5>Job title</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.jobTitle}
                      onChange={onChangeInput("jobTitle")}
                    />
                  </div>
                </label>
                <label className="input__outer">
                  <h5>Email</h5>
                  <div className="input">
                    <input
                      type="email"
                      value={form.email}
                      onChange={onChangeInput("email")}
                    />
                  </div>
                </label>
                <label className="input__outer">
                  <h5>Cell Phone</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.phone}
                      onChange={onChangeInput("phone")}
                    />
                  </div>
                </label>
                <label className="input__outer">
                  <h5>
                    Do you have any dietary restrictions? If so, please let us
                    know
                  </h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.dietaryRequirements}
                      onChange={onChangeInput("dietaryRequirements")}
                    />
                  </div>
                </label>
                <h3
                  className="request-heading"
                  style={{ marginBottom: "16px", marginTop: "12px" }}
                >
                  Flight Information
                </h3>
                <h5 className="request-subheading">
                  If confirmed, please include your flight information below.
                </h5>
                <div className="row w-100 form-subbox">
                  <div className="col-12 col-md-6">
                    <div className="request-heading-sm">Arrival</div>
                    <label className="input__outer">
                      <h5>Airline, Flight Number</h5>
                      <div className="input">
                        <input
                          type="text"
                          value={form.arrivalAirline}
                          onChange={onChangeInput("arrivalAirline")}
                        />
                      </div>
                    </label>
                    <label className="input__outer">
                      <h5>Date</h5>
                      <div className="input">
                        <DatePicker
                          selected={form.arrivalDate}
                          onChange={(date) =>
                            onChangeDateInput("arrivalDate", date)
                          }
                        />
                      </div>
                    </label>
                    <label className="input__outer">
                      <h5>Time (HH:MM)</h5>
                      <div className="input">
                        <input
                          type="text"
                          value={form.arrivalTime}
                          onChange={onChangeInput("arrivalTime")}
                        />
                      </div>
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="request-heading-sm">Departure</div>
                    <label className="input__outer">
                      <h5>Airline, Flight Number</h5>
                      <div className="input">
                        <input
                          type="text"
                          value={form.departureAirline}
                          onChange={onChangeInput("departureAirline")}
                        />
                      </div>
                    </label>
                    <label className="input__outer">
                      <h5>Date</h5>
                      <div className="input">
                        <DatePicker
                          selected={form.departureDate}
                          onChange={(date) =>
                            onChangeDateInput("departureDate", date)
                          }
                        />
                      </div>
                    </label>
                    <label className="input__outer">
                      <h5>Time (HH:MM)</h5>
                      <div className="input">
                        <input
                          type="text"
                          value={form.departureTime}
                          onChange={onChangeInput("departureTime")}
                        />
                      </div>
                    </label>
                  </div>
                </div>

                <h3
                  className="request-heading"
                  style={{ marginBottom: "16px", marginTop: "12px" }}
                >
                  About You
                </h3>
                <h5 className="request-subheading">
                  Tell everyone about yourself! We'll be compiling a welcome
                  packet which will include background information on all
                  participants.
                </h5>
                <label className="input__outer">
                  <h5>Please share a short bio (5 sentences or less)</h5>
                  <div className="input">
                    <textarea
                      type="text"
                      value={form.bio}
                      onChange={onChangeInput("bio")}
                      rows={3}
                    />
                  </div>
                </label>
                <label className="input__outer">
                  <h5>Please upload your headshot</h5>
                  <div className="input choose-file-input-outer">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={onImageUpload}
                      className="custom-file-input"
                    />
                    <span className="custom-file-button">
                      <div className="btn-text">Choose Image</div>
                    </span>{" "}
                    {form.headshotImg && (
                      <span className="selected-filename">
                        {form.headshotImg.name}
                      </span>
                    )}
                    {/* Styled custom button */}
                  </div>
                </label>
                <label className="input__outer">
                  <h5>
                    What does the the future of workforce mean to you and your
                    organization?
                  </h5>
                  <div className="input">
                    <textarea
                      type="text"
                      value={form.digitalEquity}
                      onChange={onChangeInput("digitalEquity")}
                      rows={2}
                    />
                  </div>
                </label>
                <h3
                  className="request-heading"
                  style={{ marginBottom: "16px", marginTop: "12px" }}
                >
                  Nominations
                </h3>
                <h5 className="request-subheading">
                  While this trip is invitation-only, we welcome recommendations
                  of qualified, relevant leaders in the impact space.
                </h5>
                <label className="input__outer">
                  <h5>
                    Nominate additional participants for the Learning Journey by
                    adding their names and email addresses below
                  </h5>
                  <div className="input">
                    <textarea
                      type="text"
                      value={form.nominations}
                      onChange={onChangeInput("nominations")}
                      rows={2}
                    />
                  </div>
                </label>
                {/* <h5 className="request-subheading">
                  If confirmed, please include your flight information below.
                </h5> */}
                {/* <label className="input__outer">
                  <h5>
                    Please share any additional notes on your personal interest
                    areas and active initiatives that may help us curate the
                    most relevant solutions for you (optional):
                  </h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.notes}
                      onChange={onChangeInput("notes")}
                    />
                  </div>
                </label> */}
              </div>
              <button
                type="submit"
                onClick={submitForm}
                className={`button submit-btn ${submitDisabledClass}`}
              >
                Submit
              </button>
              {formErrorMsg.length ? (
                <div className="form-invalid-msg">{formErrorMsg}</div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
      <AnimatePresence>
        {isShown && <SuccessModal setIsShown={setIsShown} />}
      </AnimatePresence>
    </>
  );
}

const RequestItemEvent = (props) => {
  const [checked, setChecked] = useState(false);
  const onChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let arr = [...props.form.events];
      arr.push(props.itemData);
      props.updateForm({ events: arr });
      console.log(
        `RequestItemEvent() - form events updated - event added`,
        arr
      );
    } else {
      const adjustedArr = props.form.events.filter(
        (item) => item.eventId !== props.itemData.eventId
      );
      props.updateForm({
        events: adjustedArr,
      });
      console.log(
        `RequestItemEvent() - form events updated - event removed`,
        adjustedArr
      );
    }
  };

  return (
    <div className="col-12 col-sm-6">
      <div className="req-item event">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <div className="req-item-content">
          <div className="req-item-upper">
            <div className="req-item-img">
              <img className="img-fluid" src={props.itemData.imageUrl} />
            </div>
            <div className="req-item-header">
              <h5>{props.itemData.title}</h5>
              <p className="sm short-desc">
                {getDisplayDateFromStartEndDateString(
                  props.itemData.startDate,
                  props.itemData.endDate,
                  props.itemData.endDateEpoch
                )}
              </p>
              <p className="sm long-desc">{props.itemData.location}</p>
            </div>
          </div>
          {/* <div className="req-item-lower">
        </div> */}
        </div>
      </div>
    </div>
  );
};

const RequestItemCompany = (props) => {
  const [checked, setChecked] = useState(false);
  const onChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let arr = [...props.form.companies];
      arr.push(props.itemData);
      props.updateForm({ companies: arr });
      console.log(
        `RequestItemCompany() - form companies updated - company added`,
        arr
      );
    } else {
      const adjustedArr = props.form.companies.filter(
        (item) => item.companyId !== props.itemData.companyId
      );
      props.updateForm({
        companies: adjustedArr,
      });
      console.log(
        `RequestItemCompany() - form companies updated - company removed`,
        adjustedArr
      );
    }
  };

  return (
    <div className="req-item">
      <input type="checkbox" checked={checked} onChange={onChange} />
      <div className="req-item-content">
        <div className="req-item-upper">
          <div className="req-item-img">
            <img className="img-fluid" src={props.itemData.imageUrl} />
          </div>
          <div className="req-item-header">
            <h5>{props.itemData.title}</h5>
            <p className="sm short-desc">{props.itemData.shortDescription}</p>
            <p className="sm long-desc">{props.itemData.longDescription}</p>
          </div>
        </div>
        {/* <div className="req-item-lower">
        </div> */}
      </div>
    </div>
  );

  return (
    <div className="requestFormItem">
      <div className="requestFormItem__inner">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <div className="requestFormItem__content">
          <div className="d-flex event-container">
            <div className="company-img">
              <img className="img-fluid" src={props.itemData.imageUrl} />
            </div>
            <div className="event-info">
              <h5>{props.itemData.title}</h5>
              <p className="sm short-desc">{props.itemData.shortDescription}</p>
              <p className="sm long-desc">{props.itemData.longDescription}</p>
              <p className="sm">{props.itemData.location}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
